<template>
  <div class="MainContent" id="about">
    <picture class="about">
        <source srcset="../assets/about.svg" media="(min-width: 1920px)" />
        <source srcset="../assets/about_sp.svg" media="(max-width: 768px)" />
        <img src="../assets/about.svg" alt="about">
    </picture>
    <img src="../assets/lever_graphic_2_fix.jpg" class="about_img2">
  </div>
  <div class="Rental formsection" id="contact">
    <div class="title grid">
      <h2>Fitting Sample Rental</h2>
      <p>フィッティング用サンプルのレンタルご希望の方は<br>以下の内容をご了承の上、コンタクトフォームよりご連絡ください。</p>
    </div>
    <div class="t_c grid">
      <h3>Terms and Conditions</h3>
      <ul>
        <li><span>1</span><p>お客様への到着後4日以内にご返送をお願いします。</p></li>
        <li><span>2</span><p>往復の送料はお客様がご負担ください。但しオーダーを頂いた場合は完成後の送料は無料といたします。</p></li>
        <li><span>3</span><p>受注会の10日前にかかる場合は、受注会後に発送させていただきます。</p></li>
        <li><span>4</span><p>お問い合わせが重なった場合は、ご連絡を頂いた順にレンタル可能なお日にちをお伝えいたします。</p></li>
      </ul>
    </div>
    <div class="grid contactform">
      <h3>Contact Form</h3>
      <a href="https://forms.gle/euU7yeXE3L63XyUw8" target="blank">こちらよりご連絡ください。</a>

        <!-- <form name="ContactRental" method="post" data-netlify="true">
        <input type="hidden" name="form-name" value="ContactRental" />
        <div>
          <label for="postcodeR"><span class="num">1</span><span>郵便番号</span></label>
          <input type="postcode" name="postcodeR" id="postcodeR"/>
        </div>
        <div>
          <label for="addlessR"><span class="num">2</span><span>住所</span></label>
          <input type="text" name="addlessR" id="addlessR"/>
        </div>
        <div>
          <label for="telR"><span class="num">3</span><span>電話番号</span></label>
          <input type="tel" name="telR" id="telR"/>
        </div>
        <div>
          <label for="nameR"><span class="num">4</span><span>氏名</span></label>
          <input type="text" name="nameR" id="nameR"/>
        </div>
        <div>
          <label for="emailR"><span class="num">5</span><span>メールアドレス</span></label>
          <input type="email" name="emailR" id="emailR"/>
        </div>
        <div>
          <label for="numR"><span class="num">6</span><span>希望する品番</span></label>
          <input type="text" name="numR" id="numR"/>
        </div>
        <div>
          <button type="submit">Send</button>
        </div>
      </form> -->
      
    </div>
  </div>

  <div class="Order formsection">
    <div class="title grid">
      <h2>Order</h2>
      <p>ご購入ご希望の方は、以下の内容をご了承の上、コンタクトフォームよりご連絡ください。</p>
    </div>
    <div class="t_c grid">
      <h3>Terms and Conditions</h3>
      <ul class="Order_t_c_lists">
        <li><span>1</span><span>お取引</span><p>1点からオーダー可能</p></li>
        <li><span>2</span><span>送料</span><p>オーダー2点以上で無料</p></li>
        <li><span>3</span><span>お支払</span><p>前払い／銀行振り込み</p></li>
        <li><span>4</span><span>ご入金</span><p>ご依頼より3日以内（クレジット決済ご希望の方は直接ご相談ください）</p></li>
        <li><span>5</span><span>受付</span><p>お支払い確認後、正式オーダーとなります。</p></li>
        <li><span>6</span><span>お渡し期日</span><p>正式オーダーより1〜3ヶ月以内(アイテムやオーダー状況によって異なります)</p></li>
        <li><span>7</span><span>修理</span><p>お渡しより1年間は無料／2年目以降は有料にて承ります。</p></li>
        <li><span>8</span><span>お振込み口座</span><p>●郵便局<br/>ゆうちょ銀行 記号:11280-3 番号:12808671 名義:ニシダミズホ<br>●他銀行<br>ゆうちょ銀行:店名:一二八(イチニハチ) 普):1280867:ニシダミズホ</p></li>
      </ul>
    </div>
    <div class="grid contactform">
      <h3>Contact Form</h3>
      <a href="https://forms.gle/euU7yeXE3L63XyUw8" target="blank">こちらよりご連絡ください。</a>
      
      <!-- <form name="Contact" method="post" data-netlify="true">
       <input type="hidden" name="form-name" value="Contact" />
        <div>
          <label for="postcode"><span class="num">1</span><span>郵便番号</span></label>
          <input type="postcode" name="postcode" id="postcode"/>
        </div>
        <div>
          <label for="addless"><span class="num">2</span><span>住所</span></label>
          <input type="text" name="addless" id="addless"/>
        </div>
        <div>
          <label for="tel"><span class="num">3</span><span>電話番号</span></label>
          <input type="tel" name="tel" id="tel"/>
        </div>
        <div>
          <label for="name"><span class="num">4</span><span>氏名</span></label>
          <input type="text" name="name" id="name"/>
        </div>
        <div>
          <label for="email"><span class="num">5</span><span>メールアドレス</span></label>
          <input type="email" name="email" id="email"/>
        </div>
        <div>
          <label for="num"><span class="num">6</span><span>希望する品番</span></label>
          <input type="text" name="num" id="num"/>
        </div>
        <div>
          <button type="submit">Send</button>
        </div>
      </form> -->

    </div>
  </div>
  <footer>
    <div class="imagearea">
      <picture>
          <source srcset="../assets/lever-image@2x.jpg" media="(min-width: 1920px)" />
          <source srcset="../assets/lever-image.jpg" media="(max-width: 768px)" />
          <img src="../assets/lever-image.jpg">
      </picture>
    </div>
    <div class="textarea grid">
      <p>L'EVER ／  リヴァーとは。<br>lever (梃子) × ever (かつて、いつでも、いつか) 洋服づくりに欠かせない道具である、鋏の構造lever(梃子)をアレンジした造語。</p>
      <p>L'EVERの洋服は、生産工程の全てを一人で行っているため、フィッティングやカウンセリングを通して、着る人の好み、適切なサイズ感、ディテールを含めた全体のバランスを見極め、一着ずつパターンを調整してオーダーする事が可能です。</p>
      <p>纏う人の個性を引き立てられるような心地の良い洋服を提案すること。vintageのように愛着を持って長く大切にしてもらえるような洋服づくりを追求し提供すること。</p>
      <p>これが私の目標です。<br/>改めまして、どうぞ宜しくお願いいたします。<br/><br/><span>L'EVER <br/>mizuho watanabe</span></p>
    </div>
    <small>©︎ 2023 L’EVER Mizuho Watanabe</small>
  </footer>

</template>

<script>
export default {
  name: 'MainContent'
};

</script>

<style lang="scss">
body{
  box-sizing: border-box;
  border: 2.5rem solid #000;background-color: #FF3131;
  @media (max-width:440px){
    border: 1.5rem solid #000;;
  }
}
li{list-style: none;}

a{text-decoration: none;color: #2c3e50;&:hover{text-decoration: underline;}}
.MainContent{
  
  .about{
    img{
      width: 80%;
    }
  }
  .about_img2{
    width: auto;
    height: 100lvh;
    display: block;
    margin: 20rem auto;
  }
  @media (max-width:440px){
  padding: 0 1rem;
  .about{
    img{
      width: 100%;
    }
  }
  .about_img2{
    width: 100%;
    height: auto;
    margin: 13rem auto;
  } 
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(12,minmax(0,1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
}

.formsection{
    padding-bottom: 10rem;
  &.Order{
    margin-bottom: 5rem;
    border-bottom: .3px solid #000;
  }
  .title{
    margin-bottom: 8rem;
    h2{
      grid-column: 5 / 13;
      position: relative;
      font-size: 4rem;
      margin-bottom: 1rem;
      &::before{
        content: '';
        grid-column: 1 / 5;
        width: 100%;
        height: .3px;
        background-color: #000;
        display: block;
        position: absolute;
        bottom: .27em;
        left: -100%;
      }
    }
    p{
      grid-column: 5 / 13;
      line-height: 1.6;
    }
  }
  .t_c{
    margin-bottom: 6rem;
    h3{
      grid-column: 2 / 5;
      + a {
        grid-column: 5 / 13;
      }
    }
    ul{
      grid-column: 5 / 13;
      padding-inline-start: 0;
      display: grid;
      grid-template-columns: repeat(8, minmax(0, 1fr));
      grid-column-gap: 1em;
      &.Order_t_c_lists{
          span{
            &:nth-child(1){
              grid-column: 1 / 2;
            }
            &:nth-child(2){
              grid-column: 2 / 4;
              white-space: nowrap;
            }
          }
          p{
            grid-column: 4 / 9;
          }
        }
      li{
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
        grid-column-gap: 1em;
        grid-column: 1 / 9;
        margin-bottom: 1.5em;
        @media(max-width:440px){
          grid-column-gap: 0;
        }
        span{
          grid-column: 1 / 2;
          display: flex;
          align-items: baseline;
          &::after{
            content: '';
            border-bottom:.3px solid #000;
            width: 100%;
          }
        }
        p{
          grid-column: 2 / 9;
          line-height: 1.6;
        }
      }
    }
  }
  .contactform{
    margin-bottom: 10em;
    h3{
      grid-column: 2 / 5;
      + a {
        grid-column: 5 / 8;
        display: inline-block;
      }
    }
    form{
        grid-column: 5 / 13;
      div{
        display: grid;
        grid-template-columns: repeat(8, minmax(0, 1fr));
        grid-column-gap: 1em;
        margin-bottom: 1em;
        grid-column: 1 / 9;
        label{
          grid-column: 1 / 4;
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-column-gap: 1em;
          padding-top: 1em;
          margin-bottom: -0.27em;
          span{
            display: flex;
            align-items: baseline;
            .num{
              grid-column: 1 / 2;
            }
            &:nth-child(2){
              grid-column: 2 / 4;
              white-space: nowrap;
            }
            &::after{
              content: '';
              border-bottom:.3px solid #000;
              width: 100%;
            }
          }
        }
        input{
          grid-column: 4 / 8;
          border:none;
          background-color:#D8D8D8;
          border-bottom: .3px solid #000;
        }
        button{
          grid-column: 4 / 8;
          border:none;
          background-color:#000;
          color: #fff;
          font-weight: 700;
          padding: .5em 0;
          &:hover{
            background-color:#fff;
            color: #000;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media (max-width:440px){
  padding:0 1rem 4rem;
  margin-bottom: 4rem;
  .grid{
    display: block;
  }
  &.Rental{
    border-bottom: .3px solid #000;
  }
  .title{
    margin-bottom: 2.4rem;
    h2{
      font-size: 1.6rem;
      margin-bottom: .4rem;
      
    }
    p{
      line-height: 1.6;
    }
  }
  h3{
    margin-bottom: .4rem;
  }
  .t_c{
    margin-bottom: 2.4rem;
  }
  }
}//formsection
footer{
  padding-bottom: 1rem;
  
  .imagearea{
    img{
      width: 50%;
    }
  }
  .textarea{
    border-top: .3px solid #000;
    margin-top: 5rem;
    padding: 2rem 0;
    margin-bottom:  8rem;
    span{
      font-size: .8rem;
    }
    p{
      font-size: .8rem;
      &:nth-child(1){grid-column: 3 / 5;}
      &:nth-child(2){grid-column: 5 / 7;}
      &:nth-child(3){grid-column: 7 / 9;}
      &:nth-child(4){grid-column: 9 / 11;}
    }
  }
  small{
    display: block;
    text-align: center;
  }
  @media (max-width: 440px){
    .imagearea{
      img{
        width: 80%;
      }
    } 
    .textarea{
      padding: 2rem 2em 4rem;
      row-gap: 8em;
      column-gap: 2em;
      span{
        font-size: .7rem;
      }
      p{
        font-size: .7rem;
        position: relative;
        &:nth-child(1){grid-column: 1 / 7;}
        &:nth-child(2){grid-column: 7 / 13;}
        &:nth-child(3){grid-column: 1 / 7;}
        &:nth-child(4){grid-column: 7 / 13;span{text-align: right;display: inherit;}}
      }
  }
  small{
    font-size: .7rem;
  }
  }
}
</style>

<template>
<header>
  <img src="../assets/lever_graphic_1_fix.jpg" alt="LEVER" />
  <nav>
    <ul>
      <li><img src="../assets/lever_logo.svg" alt="LEVER"></li>
      <li><a href="#about">About</a></li>
      <li><a href="#contact">Contact</a></li>
      <li><a href="https://www.instagram.com/l_ever__/" target="blank">Instagram</a></li>
    </ul>
  </nav>
</header>
</template>

<script>
export default {
  name: 'MainHeader',
}
</script>

<style lang="scss">
header{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  min-height: 100lvh;
  > img{
    width: 60%;
  }
  nav{
    text-align: right;
    ul{
      padding-inline-start: 0;
      li{
        margin-bottom: 1rem;
      }
    }
  }
  @media (max-width:440px){
    position: relative;
    padding: 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100lvh - 3.5rem);
    > img{
      width: 100%;
    }
    nav{
      position: fixed;
      bottom: 0;
      left: 2rem;
      right: 2rem;
      text-align: left;
      ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        li{
          margin-bottom: 0;
          &:nth-child(n+2){
            margin-left: 1rem;
          }
          img{
            width: 70%;
          }
        }
      }
    }
  }
  
}

</style>

<template>
  <MainHeader/>
  <MainContent/>
</template>

<script>
import MainContent from './components/MainContent.vue'
import MainHeader from './components/MainHeader.vue'
import 'normalize.css'

export default {
  name: 'App',  
  components: {
    MainContent,
    MainHeader
  }
}
</script>

<style lang="scss">
html *{
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 62.5%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //color: #2c3e50;
  color: #000;
  min-height: 100vh;
  background-color: #D8D8D8;
}
h3{
  font-size: 1.4rem;
  @media(max-width:440px){
    font-size: 1.2rem;
  }
}
p,li,span,a{
  font-size: 1rem;
  line-height: 2;
  margin-block-start: 0;
  margin-block-end: 0;
  letter-spacing: .03em;
  @media(max-width:440px){
    font-size: .7rem;
  }
}
</style>
